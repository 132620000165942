import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import print from 'vue3-print-nb'

import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'

const app = createApp(App)

// Import global css
import './assets/global.scss'

// -----------------------------------------------
// App Specific imports
// -----------------------------------------------
import AnimateList from '@/components/AnimateList.vue'
import DealBadge from '@/components/DealBadge.vue'
import DealBadgeSlim from '@/components/DealBadgeSlim.vue'
import DealLink from '@/components/DealLink.vue'
import EstateLink from '@/components/EstateLink.vue'
import ExternalLink from '@/components/ExternalLink.vue'
import FormErrorLabel from '@/components/FormErrorLabel.vue'
import HoverMegaMenu from '@/components/HoverMegaMenu.vue';
import LoadingArea from '@/components/LoadingArea.vue'
import MailToLink from '@/components/MailToLink.vue'
import Pagination from '@/components/Pagination.vue'
import SalesStatusBadge from '@/components/SalesStatusBadge.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import UserAvatarGroup from '@/components/UserAvatarGroup.vue'
import CurrencyInput  from '@/components/inputs/CurrencyInput.vue'
import IconTextInput  from '@/components/inputs/IconTextInput.vue'
import AppCalendar    from '@/components/AppCalendar.vue'

// -----------------------------------------------
// Components & Directives
// -----------------------------------------------
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge'
import Button from "primevue/button"
import BlockUI from 'primevue/blockui';
import Calendar from 'primevue/calendar'
import Card from 'primevue/card';
import Chart from 'primevue/chart'
import Checkbox from "primevue/checkbox"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Divider from "primevue/divider"
import Dropdown from "primevue/dropdown"
import InputGroup from 'primevue/inputgroup';
// import InputGroupAddon from 'primevue/inputgroupaddon';
import IconField from "primevue/iconfield"
import InputIcon from "primevue/inputicon"
import InputText from "primevue/inputtext"
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import MegaMenu from 'primevue/megamenu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import Paginator from 'primevue/paginator';
import ProgressSpinner from 'primevue/progressspinner';
import Ripple from 'primevue/ripple';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import StyleClass from 'primevue/styleclass';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Textarea from "primevue/textarea"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip'

import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from "dayjs/plugin/weekday"
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(weekday)
window.dayjs = dayjs

// app.directive('badge', Badge)
app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)
app.directive('tooltip', Tooltip)
app.directive('print', print)

app.component('Avatar', Avatar)
app.component('AvatarGroup', AvatarGroup)
app.component('Badge', Badge)
app.component('Button', Button)
app.component('BlockUI', BlockUI)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Chart', Chart)
app.component('Checkbox', Checkbox)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('InputGroup', InputGroup)
// app.component('InputGroupAddon', InputGroupAddon)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch);
app.component('MegaMenu', MegaMenu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('RadioButton', RadioButton);
app.component('ScrollPanel', ScrollPanel);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('TabPanel', TabPanel)
app.component('TabView', TabView)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('Paginator', Paginator)
app.component('ProgressSpinner', ProgressSpinner)

app.component('AnimateList', AnimateList)
app.component('ConfirmDialog', ConfirmDialog)
app.component('DealBadge', DealBadge)
app.component('DealBadgeSlim', DealBadgeSlim)
app.component('DealLink', DealLink)
app.component('EstateLink', EstateLink)
app.component('ExternalLink', ExternalLink)
app.component('FormErrorLabel', FormErrorLabel)
app.component('HoverMegaMenu', HoverMegaMenu)
app.component('LoadingArea', LoadingArea)
app.component('MailToLink', MailToLink)
app.component('Pagination', Pagination)
app.component('SalesStatusBadge', SalesStatusBadge)
app.component('UserAvatar', UserAvatar)
app.component('UserAvatarGroup', UserAvatarGroup)
app.component('CurrencyInput', CurrencyInput)
app.component('IconTextInput', IconTextInput)
app.component('AppCalendar', AppCalendar)

// -----------------------------------------------
// App Setup & Mount
// -----------------------------------------------
app.use(ConfirmationService)
app.use(router)
app.use(PrimeVue, {ripple: true}) //Use Ripple? >> app.use(PrimeVue, { ripple: true })
app.use(ToastService)

// -----------------------------------------------
// Add Pinia Store -- **ORDER MATTERS** 
// to ensure Pinia shows up in Vue devtools add this after app.use(router)
// and *before* importing useAuthStore below
// -----------------------------------------------
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(pinia)

// -----------------------------------------------
// Add Current User to Rollbar payload
// -----------------------------------------------
import RollbarPlugin from './modules/rollbar.plugin'
import { useAuthStore } from '@/stores'
const authStore = useAuthStore()

const extraPayload = {
  payload: {
    person: {
      id: authStore.currentUser?.id, // required
      username: authStore.currentUser?.username,
    }
  }
}
app.use(RollbarPlugin, extraPayload)

// -----------------------------------------------
// FINAL Step, Mount the app
// -----------------------------------------------
app.mount('#app')
