<template>
<MegaMenu :model="reportMenuItems">
    <template #item="{ item }">

        <template v-if="item.root">
            <div class="p-menuitem-link">
                <span :class="item.icon" />
                <span class="ml-2" style="line-height: 1rem;">{{ item.label }}</span>
                <!-- <span class="pi pi-angle-down ml-2 mt-1" v-if="item.items?.length > 0" /> -->
            </div>
        </template>

        <template v-else>
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a v-ripple :href="href" @click="navigate" class="p-menuitem-link">
                    <span :class="item.icon" />
                    <span class="ml-3">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else v-ripple :href="item.url" :target="item.target" class="p-menuitem-link">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </template>

    </template>
</MegaMenu>
</template>

<script setup>
import { ref } from 'vue'

const reportMenuItems = ref([
    {
        label: 'Reports',
        root: true,
        icon: 'pi pi-file-edit',
        items: [
            [
                {
                    label: 'Accounting',
                    items: [
                        { label: 'Accrual Report', route: '/reports/accrual', icon: 'pi pi-list'},
                        { label: 'Deferred Gains', route: '/reports/deferred-gains', icon: 'pi pi-list'},
                        { label: 'Pay Offs', route: '/reports/pay-offs', icon: 'pi pi-list'},
                        { label: 'Write Offs', route: '/reports/write-offs', icon: 'pi pi-list'},
                    ]
                },
                {
                    label: 'Assignments Receivable',
                    items: [
                        { label: 'Assignments Receivable by Estate', route: '/reports/ar-by-estate', icon: 'pi pi-list'},
                        { label: 'Assignments Receivable Grouped', route: '/reports/ar-grouped', icon: 'pi pi-list'},
                    ]
                },
            ],
            [
                {
                    label: 'Business',
                    items: [
                        { label: 'Broker Status', route: '/reports/broker-status', icon: 'pi pi-list'},
                        { label: 'Funding Logs', route: '/reports/funding-logs', icon: 'pi pi-list'},
                        { label: 'Leads Stats', route: '/reports/leads-stats', icon: 'pi pi-list'},
                        { label: 'Max Approval', route: '/reports/max-approval', icon: 'pi pi-list'},                        
                        { label: 'Rebate Pending', route: '/reports/rebates-pending', icon: 'pi pi-list'},
                        { label: 'Avidbank Borrowing Base', route: '/reports/avidbank-borrowing-base', icon: 'pi pi-list'},
                    ]
                },
                {
                    label: 'Post Funding',
                    items: [
                        { label: 'Estates with Potential Losses', route: '/reports/estates-with-potential-losses', icon: 'pi pi-list'},
                        { label: 'Post Funding Follow Up', route: '/reports/post-funding-follow-up', icon: 'pi pi-list'},
                        { label: 'Waiting For Filing', route: '/reports/waiting_for_filing', icon: 'pi pi-list'},
                        { label: 'Weekly Hearings', route: '/reports/weekly-hearings', icon: 'pi pi-list'},
                    ]
                }
            ],
            [
                {
                    label: 'Exports',
                    items: [
                        { label: 'Current Clients by FO', route: '/reports/current-clients', icon: 'pi pi-list'},
                        { label: 'Funded Clients Export', route: '/reports/funded-clients', icon: 'pi pi-list'},
                        { label: 'New Records Export', route: '/reports/new-records-export', icon: 'pi pi-list'},                        
                        { label: 'Gclid Export', route: '/reports/gclid-export', icon: 'pi pi-list'},                        
                    ]
                },
                {
                    label: 'Other',
                    items: [
                        { label: 'Court Details', route: '/reports/court-details', icon: 'pi pi-list'},                        
                    ]
                },                
                {
                    label: 'Lenders / Pools',
                    items: [
                        { label: 'Class A/B Note Schedule', icon: 'pi pi-list'},
                        { label: 'Class A/B Loan Statement', icon: 'pi pi-list'},
                    ]
                },
            ]
        ]
    },

    {
        label: 'Links',
        root: true,
        icon: 'pi pi-link',
        items: [
            [
                {
                    items: [
                        { label: 'Print Queue', route: '/deals/print', icon: 'pi pi-print' },
                    ]
                },
            ]
        ]
    },

])
</script>
