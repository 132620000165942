<script setup>
  import { computed, onMounted } from 'vue'
  import dealStatus from '@/utils/dealStatus'

  const props = defineProps({
    deal:      { type: Object, required: true },
    noMargins: { type: Boolean, default: false },
    status:    { type: String },
    isLinked:  { type: Boolean, default: true },
    target:    { type: String,  default: null },
  })

  const REQUIRED_FIELDS = [
    'id',
    'deal_number',
    'fo_initials',
    'holdback_flag',
    'on_hold_flag',
    'no_good_flag',
    'status',
  ]

  const status     = computed(() => props.status || props.deal.status);
  const dealNumber = computed(() => props.deal.deal_number);
  const initials   = computed(() => props.deal.fo_initials);
  const isNoGood   = computed(() => props.deal.no_good_flag);
  const isOnHold   = computed(() => props.deal.on_hold_flag);
  const isHoldBack = computed(() => props.deal.holdback_flag);

  onMounted(() => {
    validateInput()
  })

  function validateInput() {
    const missingFields = []
    REQUIRED_FIELDS.forEach((field) => {
      if ( !Object.keys(props.deal).includes(field) ) {
        missingFields.push(field)
      }
    })

    if (missingFields.length > 0) {
      const errMsg = `
        DealBadge Component:

        The following fields are required for the DealBadge component:
        
        ${missingFields.join("\n        ")}
      `

      try {
        throw new Error(errMsg)
      } catch (err) {
        console.error(err)
      }
    }
  }

</script>

<template lang="pug">
template(v-if="isLinked")
  RouterLink(:to=" '/deals/' + props.deal.id " style="display: contents;" :target="props.target")
    .deal-tag-slim(:class="dealStatus.cssClass(status)")
      .status {{ status }}
      .initials(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ initials }}
      //- .initials {{ initials }}
      .deal-number(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ dealNumber }}

template(v-else)
  .deal-tag-slim(:class="dealStatus.cssClass(status)")
    .status {{ status }}
    .initials(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ initials }}
    .deal-number(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ dealNumber }}
  
</template>

