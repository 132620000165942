<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  const searchText = ref('')

  const handleSearch = () => {
    router.push({path: '/search', query: {text: searchText.value} })
  }
</script>
  
<template lang="pug">
form(@submit.prevent="handleSearch")
  IconField(iconPosition="left")
    InputIcon(class="pi pi-search")
    InputText(
      v-model="searchText"
      type="text"
      placeholder="Search"
      class="w-12rem sm:w-full"
      autocomplete="off"
    )
</template>

<style scoped lang="scss">
</style>