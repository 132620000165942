import { createRouter, createWebHistory } from 'vue-router'

// Auth & Error Pages
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import VerifyPassword from '@/views/auth/VerifyPassword.vue'
import Login from '@/views/auth/Login.vue'
import PageNotFound from '@/views/PageNotFound.vue'
// import ServerError from '@/views/ServerError.vue'

// Regular Pages
import Pipeline from '@/views/dashboard/pipeline/PipelineDashboard.vue'
import { useAppStore, useAuthStore, useNotificationStore } from '@/stores'

const routes = [
  {
    path: '/',
    name: 'pipeline',
    component: Pipeline,
  },
  {
    path: '/lenders',
    name: 'Lenders',
    meta: { title: 'Lenders' },
    component: () => import('@/views/lenders/List.vue'),
  },
  {
    path: '/lenders/new',
    name: 'Lenders New',
    meta: { title: 'Lenders New' },
    component: () => import('@/views/lenders/New.vue'),
  },   
  {
    path: '/lenders/:id',
    name: 'Lender Detail',
    meta: { title: 'Lenders Detail' },
    component: () => import('@/views/lenders/Show.vue'),
  },
  {
    path: '/lenders/:id/edit',
    name: 'Edit Lender',
    meta: { title: 'Edit Lender' },
    component: () => import('@/views/lenders/Edit.vue'),
  },   
  {
    path: '/counties',
    name: 'Counties',
    meta: { title: 'Counties' },
    component: () => import('@/views/county/CountiesPage.vue'), 
  },
  {
    path: '/sub-sources',
    name: 'SubSources',
    meta: { title: 'Sub Sources' },
    component: () => import('@/views/sub_sources/SubSourcesPage.vue'), 
  },  
  {
    path: '/leads',
    name: 'LeadsList',
    meta: { title: 'Leads' },
    component: () => import('@/views/leads/List.vue'), 
  },
  {
    path: '/leads/:id',
    name: 'LeadsShow',
    component: () => import('@/views/leads/Show.vue'),   
  },  
  {
    path: '/deals/new',
    name: 'NewDeal',
    component: () => import('@/views/dashboard/new_deal/NewDealDashboard.vue'),
  },
  {
    path: '/deals/:id',
    name: 'deals',
    component: () => import('@/views/deal/DealPage.vue'),
  },
  {
    path: '/deals/print',
    name: 'PrintDeal',
    component: () => import('@/views/deal/PrintQueue.vue'),
  },  
  {
    path: '/estates/:id',
    name: 'estates',
    component: () => import('@/views/estate/EstatePage.vue'),
  },  
  {
    path: '/sources',
    name: 'sources',
    component: () => import('@/views/advertisers/AdvertisersPage.vue'),
  },
  {
    path: '/courts',
    name: 'courts',
    component: () => import('@/views/courts/CourtsPage.vue'),
  },  
  {
    path: '/search',
    name: 'search',
    props: route => ({ query: route.query }),
    component: () => import('@/views/SearchPage.vue'),
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/dev/Status.vue'),
  },
  {
    path: '/new-business',
    name: 'NewBusinessDashboard',
    component: () => import('@/views/dashboard/new_business/NewBusinessDashboard.vue'),
  },
  {
    path: '/business-history',
    name: 'BusinessHistory',
    component: () => import('@/views/dashboard/business/BusinessHistory.vue'),
  },  
  {
    path: '/leads-by-source',
    name: 'LeadsBySource',
    component: () => import('@/views/dashboard/leads/LeadsDashboard.vue'),
  },
  {
    path: '/reports',
    children: [
      {
        path: 'court-details',
        name: 'court-details',
        component: () => import('@/views/reports/CourtDetails.vue'),
      },      
      {
        path: 'list',
        name: 'reports-list',
        component: () => import('@/views/reports/ReportsList.vue'),
      },
      {
        path: 'funding-logs',
        component: () => import('@/views/reports/FundingLogs.vue'),
      },
      {
        path: 'write-offs',
        component: () => import('@/views/reports/WriteOffs.vue'),
      },
      {
        path: 'pay-offs',
        component: () => import('@/views/reports/PayOffs.vue'),
      },
      {
        path: 'broker-status',
        component: () => import('@/views/reports/BrokerStatus.vue'),
      },
      {
        path: 'accrual',
        component: () => import('@/views/reports/Accrual.vue'),
      },
      {
        path: 'deferred-gains',
        component: () => import('@/views/reports/DeferredGains.vue'),
      },
      {
        path: 'leads-stats',
        component: () => import('@/views/reports/LeadsStats.vue'),
      }, 
      {
        path: 'ar-by-estate',
        component: () => import('@/views/reports/ArByEstate.vue'),
      },
      {
        path: 'ar-grouped',
        component: () => import('@/views/reports/ArGrouped.vue'),
      }, 
      {
        path: 'rebates-pending',
        component: () => import('@/views/reports/RebatesPending.vue'),
      },
      {
        path: 'avidbank-borrowing-base',
        component: () => import('@/views/reports/AvidbankBorrowingBase.vue'),
      },
      {
        path: 'estates-with-potential-losses',
        component: () => import('@/views/reports/EstatesWithPotentialLosses.vue'),
      },
      {
        path: 'post-funding-follow-up',
        component: () => import('@/views/reports/PostFundingFollowUp.vue'),
      },
      {
        path: 'waiting_for_filing',
        component: () => import('@/views/reports/WaitingForFiling.vue'),
      },        
      {
        path: 'weekly-hearings',
        component: () => import('@/views/reports/WeeklyHearings.vue'),
      },      
      {
        path: 'current-clients',
        component: () => import('@/views/reports/CurrentClients.vue'),
      },
      {
        path: 'gclid-export',
        component: () => import('@/views/reports/GclidExport.vue'),
      },      
      {
        path: 'funded-clients',
        component: () => import('@/views/reports/FundedClients.vue'),
      },  
      {
        path: 'max-approval',
        component: () => import('@/views/reports/MaxApproval.vue'),
      }, 
      {
        path: 'new-records-export',
        name: 'NewRecordsExport',
        component: () => import('@/views/reports/NewRecordsExport.vue'),
      },                 
    ]
  },
  {
    path: '/users/:id/settings',
    name: 'user_settings',
    meta: {
    },
    component: () => import('@/views/users/UserSettings.vue'),
  },
  {
    path: '/admin',
    meta: {
      permittedRoles: ['admin'],
    },
    children: [ 
      {
        path: 'users',
        component: () => import('@/views/users/UserList.vue'),
      },
    ]
  },

  // --------------------------------------------------------
  // Auth and other layouts with no sidebar menu, etc.
  // --------------------------------------------------------
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      authRequired: false,
      layout: 'AuthLayout',
    },        
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      authRequired: false,
      layout: 'AuthLayout',
    },
  },
  {
    path: '/users/:id/password/edit',
    name: 'verify-password',
    props: route => ({ query: route.query }),
    component: VerifyPassword,
    meta: {
      authRequired: false,
      layout: 'AuthLayout',
    },
  },  
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      layout: 'AuthLayout',
    },    
  },

  // --------------------------------------------------------
  // Dev test pages
  // --------------------------------------------------------
  {
    path: '/dev',
    meta: {
      permittedRoles: ['admin'],      
    },
    children: [
      {
        path: 'deal-badges',
        component: () => import('@/views/dev/DealStatusDevelopment.vue'),
      },
      {
        path: 'form-testing',
        component: () => import('@/views/dev/FormTesting.vue'),
      },
      {
        path: 'loading',
        component: () => import('@/views/dev/LoadingDevelopment.vue'),
      },
      {
        path: 'error-testing',
        component: () => import('@/views/dev/ErrorTesting.vue'),
      },
      {
        path: 'app-store',
        component: () => import('@/views/dev/AppStoreDev.vue'),
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
      return { left: 0, top: 0 };
  },
})

function loginRequired(toRoute) {
  return toRoute.meta.authRequired === undefined || toRoute.meta.authRequired === true
}

function hasProperPermissions(toRoute) {
  const authStore       = useAuthStore()
  const permittedRoles = toRoute.meta.permittedRoles
  // console.log('hasProperPermissions', permittedRoles)
  if ( permittedRoles === undefined ) return true

  let canAccess = false
  authStore.currentUser.roles?.forEach((role) => {
    if ( permittedRoles.includes(role) ) canAccess = true
    // console.log('hasProperPermissions', role, canAccess)
  })
  return canAccess
}

router.beforeEach(async (to, from, next) => {
  const authStore       = useAuthStore()
  const isLoggedIn      = authStore.isLoggedIn
  const isLoginRequired = loginRequired(to)
  const isPermitted     = hasProperPermissions(to)

  document.title = to.meta?.title ?? 'IFC App'

  if ( !isLoggedIn && isLoginRequired ) {
    // route to Login page if user isn't logged in
    // console.log('router.beforeEach > redirect to /login', to)
    authStore.setRedirectUrl(to.path)
    return next('/login')
  } else if ( isLoggedIn && !isPermitted ) {
    // If user does not have proper roles, show a notification, do not route
    const notificationStore = useNotificationStore()
    notificationStore.notify({severity: 'warn', summary: `Cannot access page: ${to.fullPath}`, detail: 'You do not have proper permissions', life: 5000})
    // console.log('router.beforeEach > not permitted, notify', to)
    return false
  } else if ( !isLoginRequired || (isLoggedIn && isPermitted) ) {
    // if user is logged in and has proper permissions,
    // or if this page doesn't require a login, then route normally
    // console.log('router.beforeEach > Allowed, next()', to)
    return next()
  }
})

router.afterEach((to) => {
  // set current Layout
  const appStore = useAppStore()
  appStore.setLayout(to.meta.layout || 'DefaultLayout')

  // set previousUrl
  const authStore = useAuthStore()
  const lastPath = router.options.history.state.back
  authStore.previousUrl = lastPath
  // console.log('router.afterEach from', authStore.previousUrl)

  // clear app messages, if any
  const notificationStore = useNotificationStore()
  notificationStore.reset()
})

export default router
