<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

///////////////////////////////////////////////////////
///// IFC App Specific Code ///////////////////////////
///////////////////////////////////////////////////////
import { useAuthStore } from '@/stores/auth.store'
const authStore = useAuthStore()

const model = ref([
    {
        label: 'Dashboards',
        icon: 'pi pi-chart-line',
        items: [
            {
                label: 'New Deals',
                icon: 'pi pi-fw pi-user-plus',
                to: '/deals/new',
            },
            {
                label: 'Pipeline',
                icon: 'pi pi-fw pi-chart-line',
                to: '/',
            },
            {
                label: 'Search',
                icon: 'pi pi-fw pi-search',
                to: '/search',
            },
            {
                label: 'Print Queue',
                icon: 'pi pi-fw pi-print',
                to: '/deals/print',
            },
            {
                label: 'Leads by Source',
                icon: 'pi pi-fw pi-chart-line',
                to: '/leads-by-source',
            },
            {
                label: 'New Business',
                icon: 'pi pi-fw pi-chart-bar',
                to: '/new-business',
            },
            {
                label: 'Business History',
                icon: 'pi pi-fw pi-chart-scatter',
                to: '/business-history',
            },
        ],

    },

    {
        label: 'Records',
        icon: 'pi pi-chart-line',
        items: [
            {
              label: 'Courts',
              icon: 'pi pi-fw pi-building',
              to: '/courts',
            },
            {
              label: 'Counties',
              icon: 'pi pi-fw pi-map',
              to: '/counties',
            },
            {
              label: 'Lenders', 
              to: '/lenders', 
              icon: 'pi pi-address-book',
            },
            { 
              label: 'Web Leads', 
              to: '/leads', 
              icon: 'pi pi-cloud-download' 
            },            
            {
              label: 'Sources',
              icon: 'pi pi-fw pi-address-book',
              to: '/sources',
            },
            {
              label: 'Sub Sources',
              icon: 'pi pi-fw pi-id-card',
              to: '/sub-sources',
            },            
        ],
    },

    {
        label: 'Admin',
        icon: 'pi pi-chart-line',
        // set visible to false unless current user has admin role
        visible: authStore.currentUser.isAdmin,
        items: [
            {
                label: 'Manage Users',
                icon: 'pi pi-fw pi-users',
                to: '/admin/users',
            },
            {
                label: 'App Status',
                icon: 'pi pi-fw pi-box',
                to: '/status',
            },
            {
                label: 'Development',
                items: [
                    { label: 'Deal Badges', to: '/dev/deal-badges', icon: 'pi pi-fw pi-cog' },
                    { label: 'Form Testing', to: '/dev/form-testing', icon: 'pi pi-fw pi-cog' },
                    { label: 'Loading Spinners', to: '/dev/loading', icon: 'pi pi-fw pi-cog' },
                    { label: 'Error Testing', to: '/dev/error-testing', icon: 'pi pi-fw pi-cog' },
                    { label: 'App Store', to: '/dev/app-store', icon: 'pi pi-fw pi-cog' },
                ],
            },
        ],
    },

])
///////////////////////////////////////////////////////
///// END App Specific Code ///////////////////////////
///////////////////////////////////////////////////////

</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
