<script setup>
  import { ref, watch } from 'vue';
  import Calendar from 'primevue/calendar'
  import { useAttrs } from 'vue'
  import { toDate }    from '@/utils/format'

  const props = defineProps(['modelValue'])
  const emit  = defineEmits(['update:modelValue'])

  const model = ref()
  const attrs = useAttrs()

  const onInput = (e) => {
    if((e.target.value?.length == 10) && (toDate(e.target.value) != 'Invalid Date')){
      emit('update:modelValue', toDate(e.target.value))
    }
  }

  watch(() => props.modelValue, (newVal) => {
    model.value = toDate(newVal)
  }, {immediate: true})
</script>

<template lang="pug">
Calendar(
  v-model="model"
  v-bind="attrs"
  dateFormat="mm-dd-yy"
  showIcon
  showButtonBar 
  :manualInput="true"
  :selectOtherMonths="true"
  placeholder="mm-dd-yyyy"
  @input="onInput"
  @date-select="(val) => emit('update:modelValue', val)" 
  @clear-click="(e) => emit('update:modelValue', null)"
)
</template>

<style scoped lang="scss">
</style>