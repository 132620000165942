<template lang="pug">
LoadingArea(:loading="isLoading")

    Transition(name="fade-up")
      .grid(v-if="isVisible && !isLoading")
        div(class="col-12 lg:col-6 flex align-items-stretch")
          .card.w-full
            .flex-start
              div.w-full
                h6 AGP Growth
                Chart(type="line" :data="agpGrowthChart.data" :options="agpGrowthChart.options" class="h-27rem")
              //- div.ml-4.align-content-center
                table.table.table-xs
                  tr(v-for="(fo, idx) in currentTotals.officers" :key="idx")
                    td.bg-gray-50 {{ fo.initials }}
                    td.text-right {{ formatCurrency(fo.agp, true) }}

        //- div(class="col-12 md:col-6 lg:col-2 flex align-items-stretch")
          .card.w-full
            table.table.table-xs
              tr(v-for="(fo, idx) in currentTotals.officers" :key="idx")
                td.bg-gray-50 {{ fo.initials }}
                td.text-right {{ formatCurrency(fo.agp, true) }}

        div(class="col-12 md:col-6 lg:col-3 flex align-items-stretch")
          .card.w-full
            //- h6 Total AGP
            Chart(type="doughnut" id="totalAgp" :data="agpPieChart.data" :options="agpPieChart.options" class="flex justify-content-center h-16rem")

            .text-center.text-5xl.font-semibold.text-green-500.mt-2 {{ formatCurrency(currentGoals?.agp_total, true) }}
            Divider.my-3(type="solid" align="center"): b Total AGP
            .text-center.text-4xl.font-semibold.text-green-500 {{ currentGoals?.agp_percent}}%
            
            p
            table.w-full.font-bold.text-xl
              tbody
                tr(class="text-orange-400 hover:text-orange-600")
                  td.text-left Remaining
                  td.text-right {{formatCurrency(currentGoals?.agp_gap, true)}}
                tr(class="text-gray-500 hover:text-gray-800") 
                  td.text-left AGP Goal
                  td.text-right {{formatCurrency(currentGoals?.agp_goal, true)}}

        div(class="col-12 md:col-6 lg:col-3 flex align-items-stretch")
          .card.w-full
            //- h6 Total Funded
            Chart(type="doughnut" id="totalAgp" :data="fundedPieChart.data" :options="fundedPieChart.options" class="flex justify-content-center h-16rem")

            .text-center.text-5xl.font-semibold.text-green-500.mt-2 {{ currentGoals?.funded_total }}
            Divider.my-3(type="solid" align="center"): b Total Funded
            .text-center.text-4xl.font-semibold.text-green-500 {{ currentGoals?.funded_percent }}%
            
            p
            table.w-full.font-bold.text-xl
              tbody
                tr(class="text-orange-400 hover:text-orange-600")
                  td.text-left Remaining
                  td.text-right {{ currentGoals?.funded_gap }}
                tr(class="text-gray-500 hover:text-gray-800") 
                  td.text-left Funded Goal
                  td.text-right {{currentGoals?.funded_goal }}

    Transition(name="fade-up")
      .flex.flex-center(v-if="!isVisible && !isLoading" style="min-height: 4rem;") 
        .text-2xl.font-medium.text-gray-500 no AGP data

//- div
    //- .grid
        .col
            pre props: {{ props }}
        .col
            pre params: {{ params }}
        .col
            pre userList: {{ userList }}
    .grid
        .col
            pre agpPieChart: {{ agpPieChart }}
        .col
            pre fundedPieChart.data: {{ fundedPieChart.data }}
        .col
            pre currentGoals: {{ currentGoals }}
        .col
            pre currentTotals: {{ currentTotals }}

    //- pre agpGrowthChart: {{ agpGrowthChart }}

</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { formatDate, formatCurrency } from '@/utils/format'
import { pageError }  from '@/modules/HttpResponse'
import pipelineService  from '@/services/dashboard/pipeline.service'
import externalTooltipHandler from '@/modules/chart/ExternalTooltip';
import 'chartjs-adapter-moment';

const props = defineProps({
  dateRange: { type: Object, required: true },
  selectedOfficer: { type: Object, required: true }
})

const emit = defineEmits(['change'])

const isLoading = ref(true)

const agpGrowthChart = ref(
  {
    data: {
      labels: [], 
      datasets: [],
    },
    options: {},
  }
)

const agpPieChart = ref(
  {
    data: {},
    options: {
      cutout: '60%'
    }
  }
)

const fundedPieChart = ref(
  {
    data: {},
    options: {
      cutout: '60%'
    }
  }
)

const currentTotals         = ref({});
const currentGoals          = ref({});
const userList              = ref([])

onMounted(() => {
  agpGrowthChart.value.options = getAgpGrowthChartOptions();
  loadCharts();
});

const isVisible = computed(() => {
  return agpGrowthChart.value.data.datasets.length
})

// watch() can accept an array of functions to allow watching of multiple props simulateously
watch(() => [props.dateRange.startDate, props.dateRange.endDate, props.selectedOfficer], (newVal) => { 
    // console.log('AGP-CHARTS', 'watch', newVal, params, params.value.start_date)
    if (params.value.start_date) {
        loadCharts()
    }
})

const params = computed(() => {
    return {
        start_date: dayjs(props.dateRange.startDate?.toISOString()).utc(true).toString(),
        end_date: dayjs(props.dateRange.endDate?.toISOString()).utc(true).endOf('day').toString(),
        fo_initials: props.selectedOfficer?.initials,
    }
})

const loadCharts = () => {
  // console.log('AGP loadCharts', params.value)
  isLoading.value = true
  pipelineService.getCharts(params.value).then((data) => {
    agpGrowthChart.value.data = {
      labels: data.chartLabels,
      datasets: data.chartData.map(data => {
        return {...data, borderWidth: 2,pointStyle: 'rect',fill: false,}
      }),
    }

    agpPieChart.value.data    = data.goals && parseAgpPieChartData(data);  
    fundedPieChart.value.data = data.goals && parseFundedPieChartData(data);
    currentGoals.value        = data.goals
    currentTotals.value       = data.totals
    userList.value            = data.userList
    emit('change', {userList: userList.value})
  })
  .finally(() => isLoading.value = false)
}

const getAgpGrowthChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        elements: {
          point:{
            borderWidth: 0,
            radius: 0,
          }
        },     
        interaction: {
          mode: 'index',
          intersect: false,
        },        
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                  color: textColor
                },
            },
            tooltip: {
             callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.dataset.label + ": " + "$ " + tooltipItem.formattedValue;
                },
                beforeLabel: function(tooltipItem) {
                  return tooltipItem.dataset.label;
                },                
                afterLabel: function(tooltipItem) {
                  return tooltipItem.formattedValue;
                },
                title: function(tooltipItems) {
                  return formatDate(tooltipItems[0].label);
                },
                footer: function(tooltipItems) {
                  return 'Total: ' + formatCurrency(tooltipItems.reduce((a, b) => a + b.parsed.y, 0));
                },
              },
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler            
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD, YY'
                    }
                },
                ticks: {
                    color: textColorSecondary,
                    maxTicksLimit: 20,
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
}

const parseAgpPieChartData = (data) => {
  const documentStyle = getComputedStyle(document.body);
  const percent = data.goals['agp_percent'];
  const rest = 100-percent < 0 ? 0 : 100-percent

  return {
    // labels: ['A', 'B'],
    datasets: [
      {
        data: [percent, rest],
        backgroundColor: [documentStyle.getPropertyValue('--green-500'), documentStyle.getPropertyValue('--gray-100')],
        hoverBackgroundColor: [documentStyle.getPropertyValue('--green-400'), documentStyle.getPropertyValue('--gray-400')],
        hoverOffset: 4
      }
    ]
  };
}

const parseFundedPieChartData = (data) => {
  const documentStyle = getComputedStyle(document.body);
  const percent = data.goals['funded_percent'];
  const rest = 100-percent < 0 ? 0 : 100-percent

  return {
    // labels: ['A', 'B'],
    datasets: [
      {
        data: [percent, rest],
        backgroundColor: [documentStyle.getPropertyValue('--green-500'), documentStyle.getPropertyValue('--gray-100')],
        hoverBackgroundColor: [documentStyle.getPropertyValue('--green-400'), documentStyle.getPropertyValue('--gray-400')],
        hoverOffset: 4
      }
    ]
  };
}
</script>