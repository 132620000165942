<template lang="pug">

//- isLinked is used to control whether the deal badge is wrapped inside an <a> tag or not
//- The DOM elements are identical whether we link the deal or not

template(v-if="isLinked")
  RouterLink(:to=" '/deals/' + props.deal.id " style="display: contents;")
    .deal-badge(:class="dealStatus.cssClass(status)" :style="props.noMargins ? 'margin: 0;' : '' ")
      .deal-badge-top-half
        .status-text {{ status }}
      .deal-badge-bottom-half(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}")
        span.initials-text(nowrap) {{ initials }} {{ dealNumber }}

template(v-else)
  .deal-badge(:class="dealStatus.cssClass(status)" :style="props.noMargins ? 'margin: 0;' : '' ")
    .deal-badge-top-half
      .status-text {{ status }}
    .deal-badge-bottom-half(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}")
      span.initials-text(nowrap) {{ initials }} {{ dealNumber }}

</template>

<script setup>
  import { computed, onMounted } from 'vue'
  import dealStatus from '@/utils/dealStatus'

  const props = defineProps({
    deal:      { type: Object, required: true },
    status:    { type: String },
    noMargins: { type: Boolean, default: false },
    isLinked:  { type: Boolean, default: true },
  })

  const status     = computed(() => props.status || props.deal.status);
  const dealNumber = computed(() => props.deal.deal_number);
  const initials   = computed(() => props.deal.fo_initials);
  const isNoGood   = computed(() => props.deal.no_good_flag);
  const isOnHold   = computed(() => props.deal.on_hold_flag);
  const isHoldBack = computed(() => props.deal.holdback_flag);

  const REQUIRED_FIELDS = [
    'id',
    'deal_number',
    'fo_initials',
    'holdback_flag',
    'on_hold_flag',
    'no_good_flag',
    'status',
  ]

  onMounted(() => {
    validateInput()
  })

  function validateInput() {
    const missingFields = []
    REQUIRED_FIELDS.forEach((field) => {
      if ( !Object.keys(props.deal).includes(field) ) {
        missingFields.push(field)
      }
    })

    if (missingFields.length > 0) {
      const errMsg = `
        DealBadge Component:

        The following fields are required for the DealBadge component:
        
        ${missingFields.join("\n        ")}
      `

      try {
        throw new Error(errMsg)
      } catch (err) {
        console.error(err)
      }
    }
  }

</script>
