<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from './composables/layout';
import AppSidebar from './AppSidebar.vue';
import { usePrimeVue } from 'primevue/config';

const $primevue = usePrimeVue();

defineExpose({
    $primevue
});
const { isHorizontal, onMenuToggle, showConfigSidebar, showSidebar } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
const onMenuButtonClick = () => {
    onMenuToggle();
};

// const onConfigButtonClick = () => {
//     showConfigSidebar();
// };
// const onSidebarButtonClick = () => {
//     showSidebar();
// };

///////////////////////////////////////////////////////
///// IFC App Specific Code ///////////////////////////
///////////////////////////////////////////////////////
import ReportsMegaMenu           from '@/views/_layouts/_includes/ReportsMegaMenu.vue'
import SearchNavbarInput         from '@/views/_layouts/_includes/SearchNavbarInput.vue'
import ToggleDarkModeButton      from '@/views/_layouts/_includes/ToggleDarkModeButton.vue'
import UserSettingsAndLogoutMenu from '@/views/_layouts/_includes/UserSettingsAndLogoutMenu.vue'
///////////////////////////////////////////////////////
///// END App Specific Code ///////////////////////////
///////////////////////////////////////////////////////
</script>

<template>
    <div class="layout-topbar">

        <div class="topbar-start">
            <Button ref="menubutton" type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" @click="onMenuButtonClick()">
                <i class="pi pi-bars"></i>
            </Button>
        
            <ReportsMegaMenu></ReportsMegaMenu>
        </div>
        
        <div class="layout-topbar-menu-section">
            <AppSidebar></AppSidebar>
        </div>
        
        <div class="topbar-end">
            <ul class="topbar-menu">

                <!-- Search Input -->
                <li :class="isHorizontal ? 'topbar-search hidden' : 'topbar-search hidden sm:block'">
                    <SearchNavbarInput/>
                </li>

                <li :class="isHorizontal ? 'block topbar-item' : 'block sm:hidden topbar-item'">
                    <a v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }" v-ripple>
                        <Button type="button" icon="pi pi-search" text severity="secondary"></Button>
                    </a>
                    <ul :class="'hidden topbar-menu active-topbar-menu p-3 w-15rem  z-5'" style="bottom: -5.8rem">
                        <SearchNavbarInput/>
                    </ul>
                </li>

                <li class="topbar-item">
                    <UserSettingsAndLogoutMenu/>
                </li>

                <li>
                    <ToggleDarkModeButton/>
                </li>

<!-- 
                <li>
                    <Button type="button" icon="pi pi-cog" class="flex-shrink-0" text severity="secondary" @click="onConfigButtonClick()"></Button>
                </li>
                <li>
                    <Button type="button" icon="pi pi-arrow-left" class="flex-shrink-0" text severity="secondary" @click="onSidebarButtonClick()"></Button>
                </li>

 -->

            </ul>
        </div>
    </div>
</template>
